import React from "react"
import { Paragraph, Link } from "evergreen-ui"
import StartButton from "../components/StartButton"

export default ({ location }) => {
  return (
    <>
      <Paragraph size={500} marginTop="default">
        Chère Madame, Cher Monsieur,
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Nous vous remercions pour votre intérêt à rejoindre notre réseau de
        partenaires du Commerce en ligne Goodyear. Notre objectif est d’offrir
        une haute qualité de service à nos acheteurs en ligne. Vous recevrez une
        rémunération pour votre prestation, et une prime de réception pour
        chaque pneumatique. Vous pouvez consulter la{" "}
        <Link href="/videos/fr/video.mp4" target="_blank" size={500}>
          vidéo
        </Link>{" "}
        et le{" "}
        <Link
          href="/manuals/Manuel_du_prestataire_eCommerce_Goodyear.pdf"
          size={500}
        >
          manuel
        </Link>{" "}
        ci-joints.
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Merci de bien vouloir suivre les étapes du processus d'enregistrement en
        ligne.
      </Paragraph>

      <Paragraph size={500} marginTop="default">
        Après avoir finalisé votre enregistrement, vous recevrez une copie des
        données communiquées. Après évaluation de conformité, Goodyear vous
        confirmera l'activation du partenariat.
      </Paragraph>

      <Paragraph size={500} marginTop="default" marginBottom="default">
        Nous vous remercions une nouvelle fois d’avoir choisi de rejoindre le
        réseau Goodyear eCommerce et espérons vous accueillir rapidement en tant
        que prestataire de service.
      </Paragraph>
      <StartButton search={location.search} />
    </>
  )
}
